import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-hijri';
import "./index.css"
import { useTranslation } from "../../../contexts/TranslationContext";
const HijriDateSelector = ({ onDateChange, defaultValue }) => {
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');

    const { translate } = useTranslation();


    const currentHijriYear = moment().iYear();
    const months = moment.localeData('ar-sa')._iMonths;

    const days = Array.from({ length: 30 }, (_, i) => i + 1); // 1 to 30
    const years = Array.from({ length: 100 }, (_, i) => currentHijriYear - i).filter(year => year <= currentHijriYear - 19);

    // Set default values when the component is first rendered
    useEffect(() => {
        if (defaultValue) {
            const [day, month, year] = defaultValue.split('/');

            const formattedMonth = String(month).padStart(2, '0'); // Ensures two-digit month
            const formattedDay = String(day).padStart(2, '0'); // Ensures two-digit day

            setSelectedYear(year);
            setSelectedMonth(formattedMonth);
            setSelectedDay(formattedDay);
        }
    }, [defaultValue]);

    // Emit the formatted date to the parent component when all fields are selected
    const prevSelectedDay = useRef(selectedDay);
    const prevSelectedMonth = useRef(selectedMonth);
    const prevSelectedYear = useRef(selectedYear);

    useEffect(() => {
        if (
            selectedDay !== prevSelectedDay.current ||
            selectedMonth !== prevSelectedMonth.current ||
            selectedYear !== prevSelectedYear.current
        ) {
            if (selectedDay && selectedMonth && selectedYear) {
                const formattedMonth = String(selectedMonth).padStart(2, '0');
                const formattedDay = String(selectedDay).padStart(2, '0');
                const formattedDate = `${formattedDay}/${formattedMonth}/${selectedYear}`;
                onDateChange(formattedDate);
            }
            if (selectedDay == '' || selectedMonth == "" || selectedYear == '') {
                console.log('empty date field in hijri')
                onDateChange('')
            }

            prevSelectedDay.current = selectedDay;
            prevSelectedMonth.current = selectedMonth;
            prevSelectedYear.current = selectedYear;
        }
    }, [selectedDay, selectedMonth, selectedYear, onDateChange]);

    return (
        <div className="hijri-date-selector">
            <select value={selectedDay} onChange={(e) => setSelectedDay(e.target.value)}>
                <option value="">{translate('INPUTS.DAY')}</option>
                {days.map((day) => (
                    <option key={day} value={String(day).padStart(2, '0')}>
                        {day}
                    </option>
                ))}
            </select>

            <select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                <option value="">{translate('INPUTS.MONTH')}</option>
                {months.map((month, index) => (
                    <option key={index} value={String(index + 1).padStart(2, '0')}>
                        {month}
                    </option>
                ))}
            </select>

            <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
                <option value="">{translate('INPUTS.YEAR')}</option>
                {years.map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default HijriDateSelector;
