import { UploadOutlined } from '@ant-design/icons';
import { Typography, Upload } from 'antd';
import axios from "axios";
import { FormDrawer, buildInitialValues, buildValidationSchema } from 'Components';
import { useTranslation } from "contexts/TranslationContext";
import { withFormik } from 'formik';
import useAuthHook from "hooks/useAuthHook";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import {
  useCreateNewAppMutation,
  useDeleteApplMutation,
  useUpdateAppDetailsMutation
} from "services/auth";
import { useLazyGetBusinessProviderAltQuery } from "services/lookup";
import BackButton from 'shared/components/BackButton/BackButton';
import { Button } from 'shared/components/Button';
import env from "shared/constants/env";
import routes, { appRoute } from 'shared/constants/routes';
import getGeoLocation from "utilities/getGeoLocation";
import { partnersSchema } from './schema';

const { Title, Text } = Typography;
const { Dragger } = Upload;

const Provider = withFormik({
  mapPropsToValues: () => buildInitialValues(partnersSchema({
    hideRequestedLoanAmount: true,
  })),
  validationSchema: buildValidationSchema(partnersSchema({
    hideRequestedLoanAmount: true,
  })),
  validateOnMount: true,
  validateOnChange: true,
  validateOnBlur: true,
  enableReinitialize: true
})(props => {
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const { id, partnerId } = useParams();
  const [uploadFile, setUploadFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const { getUser } = useAuthHook();

  const [fetchProviders, { data: partner }] =
    useLazyGetBusinessProviderAltQuery();

  const [createNewApp, { isLoading: createNewAppLoading }] = useCreateNewAppMutation();
  const [deleteAppl] = useDeleteApplMutation();
  const [updateApplicationDetails, { isLoading: updateApplicationDetailsLoading }] = useUpdateAppDetailsMutation();

  const handleFetchProviders = async () => {
    const queryParams = `&BPTYPEID=${partnerId}`;
    fetchProviders({ id, queryParams });
  };

  useEffect(() => {
    handleFetchProviders();
  }, []);

  const user = getUser();
  const idbUserId = user ? String(user.IDB_USR_ID) : null;

  const onBack = () => {
    navigate(-1);
  };

  const handleRemove = (e) => {
    setFileList(prev => prev.filter(i => i.uid !== e.uid));
    setUploadFile(null)
  }

  const beforeUpload = (file) => {
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      toast.error(translate("SETTINGS.ERROR_FILE_TYPE"));
      return false;
    }

    const isLt3M = file.size / 1024 / 1024 < 3;
    if (!isLt3M) {
      toast.error(translate("SETTINGS.ERROR_FILE_SIZE_3MB"));
      return false;
    }

    const fileName = file.name;
    const multipleExtensionsPattern = /(\.[^.\s]{2,4}){2,}$/;
    if (multipleExtensionsPattern.test(fileName)) {
      toast.error(translate("SETTINGS.ERROR_FILE_MULTIPLE_EXT"));
      return false;
    }
    const invalidFileNamePattern = /^[^.]+\.[^.]+\..+\.[^.]+$/;

    if (invalidFileNamePattern.test(fileName)) {
      toast.error(translate("SETTINGS.ERROR_FILE_MULTIPLE_EXT"));
      return false;
    }

    if (fileName.toLowerCase().includes('contract')) {
      toast.error(translate("SETTINGS.ERROR_FILE_NAME_CONTRACT"));
      return false
    }
    setUploadFile(file)
    return false;
  };

  const validateFile = (file) => {
    const isValidType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "application/pdf";

    if (!isValidType) {
      return false;
    }

    const isUnder3MB = file.size / 1024 / 1024 < 3;
    if (!isUnder3MB) {
      return false;
    }

    const fileName = file.name;
    const multipleExtensionsPattern = /(\.[^.\s]{2,4}){2,}$/;
    if (multipleExtensionsPattern.test(fileName)) {
      return false;
    }

    const invalidFileNamePattern = /^[^.]+\.[^.]+\..+\.[^.]+$/;
    if (invalidFileNamePattern.test(fileName)) {
      return false;
    }

    if (fileName.toLowerCase().includes("contract")) {
      return false;
    }

    return true; // File is valid
  };
  const dragAndDropProps = {
    name: 'file',
    multiple: true,
    maxCount: 3,
    accept: "image/png,image/jpeg,image/jpg,application/pdf",
    beforeUpload,
    fileList,
    onChange(info) {
      if (info.file.status === 'error' || info.file.status === 'removed') {
        setFileList(prev => prev.filter(i => i.uid !== info.file.uid))
        return
      }
      const filteredFiles = [...fileList, info.file].filter(file => validateFile(file));
      setFileList(filteredFiles);
    },
    onDrop(e) {
      const droppedFiles = [...e.dataTransfer.files].filter(file => validateFile(file));
      setFileList(prev => [...prev, ...droppedFiles]);
    }
  };


  const schemaFields = useMemo(
    () => {
      let hideRequestedLoanAmount = false;

      return partnersSchema({
        hideRequestedLoanAmount: hideRequestedLoanAmount
      })
    },
    []
  );

  const uploadImage = async (file, appId) => {
    const formData = new FormData();
    formData.append("document", file);
    formData.append("appId", appId);
    const data = JSON.parse(localStorage.getItem("@@TAMWEEL_USER"));
    await axios
      .post(`${env.API_ENDPOINT}app/upload-document`, formData, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      })
  }

  const createApp = async () => {
    try {
      if (!user) return;
      const res = await createNewApp({
        id: String(user?.IDB_USR_ID),
        idNumber: String(user?.USER_ID),
        idType: String(user?.USER_ID)[0] === "1" ? "NID" : "Q",
      }).unwrap();
      if (res?.query) {
        return res.query
      }
      return null
    } catch (ex) {
      return null
    }
  };

  const updateApp = async (appId) => {
    const { latitude, longitude } = await getGeoLocation()
    const details = {
      lat: latitude,
      long: longitude,
      request_amount: props.values.request_amount,
      salesRef: props.values.salesRef,
      tenure: props.values.tenure,
      applStatus: "S",
      productId: '328',
      subproductId: '329',
      source: 'WEB',
    }
    try {
      const response = await updateApplicationDetails({
        appId,
        userWebId: idbUserId,
        details,
      });
      if (response.error) {
        throw new Error('Error in updating application details.')
      }
      return response

    } catch (ex) {
      throw ex
    }
  }
  const handleSubmit = async () => {
    const { request_amount, tenure } = props.values
    if (!request_amount || !tenure || !props.isValid) {
      toast.error(translate("VALIDATION.FILL_REQUIRED_FIELDS"));
      return
    }

    const applicationId = await createApp()
    if (!applicationId) {
      toast.error(translate("PROVIDERS.SUBMIT_APPL_ERROR"));
      return
    }

    try {
      const uploadPromises = fileList.map(async (file) => {
        try {
          await uploadImage(file, applicationId);
        } catch (error) {
          console.error("Error uploading image:", file.name, error);
          throw error;
        }
      });

      await Promise.all([updateApp(applicationId), ...uploadPromises]);

      setUploadFile(null);
      setFileList([]);

      toast.success(translate("PROVIDERS.SUBMIT_APPL_SUCEESS"));
      navigate(appRoute(`${routes.PARTNERS}/${id}/${partnerId}/application/${applicationId}/success`));
    } catch (err) {
      console.error("Error during app update:", err);
      toast.error(translate("PROVIDERS.SUBMIT_APPL_ERROR"));
      await deleteAppl(applicationId)
    }
  }

  return (
    <div className="rounded-xl min-h-16 mb-4 px-8 py-4 bg-white shadow-[0_4px_10px_-2px_rgba(0,0,0,0.1)]">
      {/* Header Section */}
      <BackButton onClick={onBack} />

      {/* Form Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left Form */}
        <div>
          <div className='mb-4 my-2'>
            <div>
              <Title level={3} style={{
                color: 'rgba(0, 110, 126, 1)',
                fontWeight: 700
              }}>

                {partner?.[0]?.BPTYPE}
              </Title>
              <Text className="text-gray-500">
                {partner?.[0]?.BPDESCRIPTION}
              </Text>
            </div>
          </div>

          <FormDrawer schema={{
            fields: schemaFields,
          }} />

        </div>

        {/* Right Form */}
        <div className='my-4'>
          <label className="block text-gray-600 font-medium mb-2">{translate("SETTINGS.DOCUMENT")}</label>
          <div className="border-dashed border-2 border-gray-300 rounded-md p-6 mb-4 text-center">
            <Dragger
              {...dragAndDropProps} onRemove={e => handleRemove(e)}
              className="!border-none"
            >
              <UploadOutlined className="text-3xl text-teal-600 mb-2" />
              <p className="text-teal-600 font-medium">{translate("SETTINGS.DOCUMENTS_DRAG_DROP_HEADING")}</p>
              <br />
              <Text type="secondary" className="text-xs">
                {translate("SETTINGS.DOCUMENTS_DRAG_DROP_TEXT")}
              </Text>
            </Dragger>
          </div>
        </div>
      </div>

      {/* Apply Button */}
      <div className="flex justify-end mt-8">
        <Button
          type="primary"
          onClick={handleSubmit}
          // onClick={props.handleSubmit}
          disabled={createNewAppLoading || updateApplicationDetailsLoading}
          className="bg-teal-700 hover:bg-teal-800 px-8 py-2 text-white rounded-md"
        >
          {translate('APPLY')}
        </Button>
      </div>
    </div>
  );
});

export default Provider;
