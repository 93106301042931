import { Card, Modal, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shared/components/Button';
import routes from 'shared/constants/routes';
import { SET_ACTIVE_STEP_INDEX, useCreateRequestContext } from './CreateRequestContext';
import { useTranslation } from "../../../contexts/TranslationContext";
import ReactStars from 'react-stars';
import { toast } from "react-toastify";
import env from "shared/constants/env";

export default function Success() {
  const { state, dispatch } = useCreateRequestContext();
  // States for rating and modal
  const [rating, setRating] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [showThanks, setShowThanks] = useState(false);

  const navigate = useNavigate();
  const { translate, language } = useTranslation();
  const isRTL = language === "ar" ? false : true;

  useEffect(() => {
    dispatch({ type: SET_ACTIVE_STEP_INDEX, payload: 4 });
  }, [dispatch]);

  const handleRateChange = (newRating) => {
    setRating(newRating);
    if (newRating > 0 && newRating < 3) {
      setIsModalOpen(true);
    } else {
      setShowThanks(true);
    }
  };

  const handleModalOk = () => {
    setIsModalOpen(false);
    console.log("Rating:", rating);
    console.log("Comment:", comment);
  };

  const handleSubmit = async () => {
    console.log(state.appId, rating, comment, JSON.parse(localStorage.getItem("@@TAMWEEL_USER"))?.IDB_USR_ID);

    if (rating === 0) {
      navigate(`/${routes.APP_ROOT}/${routes.HOME}`);
    }

    if (rating > 0) {
      const response = await fetch(`${env.API_ENDPOINT}app/appl_rating`,
        {
          method: 'POST',
          body: JSON.stringify({
            applId: Number(state.appId),
            stars: rating,
            id: JSON.parse(localStorage.getItem("@@TAMWEEL_USER"))?.IDB_USR_ID,
            ...(comment && { comment: comment })
          })
        });
      const data = await response.json();

      if (data?.success) {
        toast.success("Feedback submitted successfully.");
        navigate(`/${routes.APP_ROOT}/${routes.HOME}`);
      }
      else {
        toast.error("Failed to get feedback.");
      }
    }
  }

  return (
    <div className="mt-8 flex items-center justify-center">
      <Card className="w-96">
        <div className="text-center">
          <h2 className="font-bold text-xl mb-4 text-green-500 uppercase">{translate('CREATE_REQUEST.GREAT')}</h2>
          <div className="mb-4">
            <div className="font-bold uppercase">{translate('CREATE_REQUEST.YOUR_REQUEST')}</div>
            <div>{translate('CREATE_REQUEST.SUBMITTED_FOR_REVIEW')}</div>
          </div>
        </div>
        {/* <div className="mb-4">{translate('CREATE_REQUEST.THANKS_FOR_APPLYING')}</div> */}
        <div className="mb-4 rounded-lg border border-gray-200 px-2 py-2">
          <div className="font-bold">{translate('CREATE_REQUEST.APP_NO')}</div>
          <div>{state?.appId}</div>
        </div>

        <div className="mb-4">
          <div className="font-bold text-center">{comment ? comment : translate("CREATE_REQUEST.FEEDBACK")}</div>
          {!comment && <div className="mb-2 text-center">{translate("CREATE_REQUEST.ONLY_ONE_MINUTE")}</div>}
          <div className="flex justify-center">
            <ReactStars
              count={5}
              value={rating}
              size={30}
              color2={"#ffd700"}
              onChange={handleRateChange}
              char={"★"}
              direction={isRTL ? "rtl" : "ltr"}
            />
          </div>
          <div className="text-gray-400 mb-2 text-sm text-center">{translate("CREATE_REQUEST.THANKS_FOR_FEEDBACK")}</div>
        </div>

        <Button
          onClick={() => handleSubmit()}
          color="primary"
          className="mb-4 rounded-lg font-bold" block>{translate('CREATE_REQUEST.DONE')}</Button>
        <div className="text-center">
          <div>{translate('CREATE_REQUEST.WE_WILL_GET_BACK')}</div>
          <div>{translate('CREATE_REQUEST.KINDLY_MESSAGE')}</div>
          <div>{translate('CREATE_REQUEST.FURTHER_ASSISTANCE')}</div>
        </div>
      </Card>

      <Modal
        open={isModalOpen}
        centered
        // closeIcon={<span className="text-gray-500 text-lg cursor-pointer">✖</span>}
        footer={null}
        onCancel={() => {
          setComment("");
          setIsModalOpen(false);
        }
        }
      >
        <div className="text-center">
          <h2 className="font-bold text-lg mb-2">{translate("CREATE_REQUEST.WRITE_COMMENT")}</h2>
          <p className="text-gray-500 mb-4 text-sm">{translate("CREATE_REQUEST.COMMENT_SUB")}</p>

          <Input.TextArea
            maxLength={250}
            rows={4}
            value={comment}
            onChange={(e) => {
              const value = e.target.value;
              const sanitizedValue = value.replace(/[^a-zA-Z\u0600-\u06FF0-9\s.,]/g, "");  // Allows Arabic, English, numbers, spaces, dots, commas
              const words = sanitizedValue.split(" ");
              const truncatedWords = words.map(word => word.length > 20 ? word.slice(0, 20) : word);
              setComment(truncatedWords.join(" "));
            }}
            className="w-full p-2 border rounded-md"
          />

          <Button
            onClick={handleModalOk}
            color="primary"
            className="w-full mt-4 rounded-md py-2 font-bold"
          >
            {translate("CREATE_REQUEST.ADD_COMMENT")}
          </Button>
        </div>
      </Modal>

    </div >
  );
}
